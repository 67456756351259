export enum fields {
    SIMPLE_STATE = "SIMPLE_STAT",
    CURRENT_CH = "CURRENT_CH",
    IS_VISIBLE = "IS_VISIBLE",
    QUALIFICATION = "QUALIFICATION",
    QUANTITY_RPD = "QUANTITY_RPD",
    YEAR = "YEAR",
    QUANTITY_OP = "QUANTITY_OP",
    RPD_WITHOUT_SU = "RPD_WITHOUT_SU",
    RPD_IN_SU = "RPD_IN_SU",
    STATUS = "STATUS",
    SEMESTER = "SEMESTER",
    SU = "SU",
    AP = "AP",
    APuse = "APuse",
    RPD_IN_AP = "RPD_IN_AP",
    SUuse = "SUuse",
    RPD_IN_SEMESTER = "RPD_IN_SEMESTER"
}


export enum SimpleStatFields {
    REGISTERED_USERS = 'REGISTERED_USERS',
    USERS_IN_RPD = 'USERS_IN_RPD',
    RPD_WITH_EDITORS = 'RPD_WITH_EDITORS',
    RPD_ON_EXPERTISE = 'RPD_ON_EXPERTISE',
    RPD_APPROVED = 'RPD_APPROVED',
    RPD_IN_WORK = 'RPD_IN_WORK',
}