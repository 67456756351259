export enum fields {
    WORK_PROGRAM_LIST = 'WORK_PROGRAM_LIST',
    WORK_PROGRAM_DIALOG = 'WORK_PROGRAM_DIALOG',
    IS_OPEN_DIALOG = 'IS_OPEN_DIALOG',
    DIALOG_DATA = 'DIALOG_DATA',
    SEARCH_QUERY = 'SEARCH_QUERY',
    CURRENT_PAGE = 'CURRENT_PAGE',
    ALL_COUNT = 'ALL_COUNT',
    SORTING = 'SORTING',
    SORTING_FIELD = 'SORTING_FIELD',
    SORTING_MODE = 'SORTING_MODE',
    FILTERING = 'FILTERING',
    WORK_PROGRAM_ID_FOR_REDIRECT = 'WORK_PROGRAM_ID_FOR_REDIRECT',
}

export enum fetchingTypes {
    GET_WORK_PROGRAM_LIST = 'GET_WORK_PROGRAM_LIST',
    DELETE_WORK_PROGRAM = 'DELETE_WORK_PROGRAM',
    CREATE_WORK_PROGRAM = 'CREATE_WORK_PROGRAM',
}

export enum filterFields{
    LANGUAGE = 'LANGUAGE',
    NUMBER_OP = 'NUMBER_OP',
    NAME_OP = 'NAME_OP',
    SPECIALIZATION = 'SPECIALIZATION',
    STRUCTURAL_UNIT = 'STRUCTURAL_UNIT',
    ONLY_MY = 'ONLY_MY',
    ARCHIVE = 'ARCHIVE',
    PREREQUISITE = 'PREREQUISITE',
    OUTCOMES = 'OUTCOMES',
    EDU_PROGRAM = 'EDU_PROGRAM',
    STATUS = 'STATUS'
}
