export const positionsListArray = [
    {
        value: 'leader',
        label: 'Руководитель'
    },
    {
        value: 'deputy',
        label: 'Заместитель'
    }
];

export const positionsListObject = {
    leader: 'Руководитель',
    deputy: 'Заместитель',
};