export enum fields {
  ENTITY_TO_ENTITY_LIST = 'ENTITY_TO_ENTITY_LIST',
  ENTITY_TO_ENTITY_LIST_DIALOG = 'ENTITY_TO_ENTITY_LIST_DIALOG',
  IS_OPEN_DIALOG = 'IS_OPEN_DIALOG',
  DIALOG_DATA = 'DIALOG_DATA',
  SEARCH_QUERY = 'SEARCH_QUERY',
  CURRENT_PAGE = 'CURRENT_PAGE',
  ALL_COUNT = 'ALL_COUNT',
  SORTING = 'SORTING',
  SORTING_FIELD = 'SORTING_FIELD',
  SORTING_MODE = 'SORTING_MODE',
  SUBJECT_ID = 'SUBJECT_ID',
}

export enum fetchingTypes {
  GET_ENTITY_TO_ENTITY = 'GET_ENTITY_TO_ENTITY',
  DELETE_ENTITY_TO_ENTITY = 'DELETE_ENTITY_TO_ENTITY',
  UPDATE_ENTITY_TO_ENTITY = 'UPDATE_ENTITY_TO_ENTITY',
  CREATE_ENTITY_TO_ENTITY = 'CREATE_ENTITY_TO_ENTITY',
}

export enum EntityToEntityFields {
  ID = 'id',
  ITEM1 = 'item1',
  ITEM2 = 'item2',
  RELATION = 'relation',
}