export enum fields {
    STRUCTURAL_UNIT_LIST = 'STRUCTURAL_UNIT_LIST',
    STRUCTURAL_UNIT_DIALOG = 'STRUCTURAL_UNIT_DIALOG',
    IS_OPEN_DIALOG = 'IS_OPEN_DIALOG',
    DIALOG_DATA = 'DIALOG_DATA',
    SEARCH_QUERY = 'SEARCH_QUERY',
    CURRENT_PAGE = 'CURRENT_PAGE',
    ALL_COUNT = 'ALL_COUNT',
    SORTING = 'SORTING',
    SORTING_FIELD = 'SORTING_FIELD',
    SORTING_MODE = 'SORTING_MODE',
    STRUCTURAL_UNIT = 'STRUCTURAL_UNIT',
}

export enum fetchingTypes {
    GET_STRUCTURAL_UNITS = 'GET_STRUCTURAL_UNITS',
    DELETE_STRUCTURAL_UNITS = 'DELETE_STRUCTURAL_UNITS',
    UPDATE_STRUCTURAL_UNITS = 'UPDATE_STRUCTURAL_UNITS',
    CREATE_STRUCTURAL_UNITS = 'CREATE_STRUCTURAL_UNITS',
    GET_STRUCTURAL_UNIT = 'GET_STRUCTURAL_UNIT',
    ADD_USER_TO_STRUCTURAL_UNIT = 'ADD_USER_TO_STRUCTURAL_UNIT',
    REMOVE_USER_FROM_STRUCTURAL_UNIT = 'REMOVE_USER_FROM_STRUCTURAL_UNIT',
    UPDATE_USER_FROM_STRUCTURAL_UNIT = 'UPDATE_USER_FROM_STRUCTURAL_UNIT',
}

export enum structuralUnitFields {
    ID = 'id',
    TITLE = 'title',
    USERS = 'user_in_structural_unit',
}

export enum structuralUnitUserFields {
    ID = 'id',
    STRUCTURAL_UNIT = 'structural_unit',
    USER = 'user',
    STATUS = 'status',
}
