export enum fields {
    COMPETENCE_LIST = 'COMPETENCE_LIST',
    COMPETENCE_DIALOG = 'COMPETENCE_DIALOG',
    IS_OPEN_DIALOG = 'IS_OPEN_DIALOG',
    DIALOG_DATA = 'DIALOG_DATA',
    SEARCH_QUERY = 'SEARCH_QUERY',
    SEARCH_CODE = 'SEARCH_CODE',
    FILTER_ONLY_WITH_STANDARD = 'FILTER_ONLY_WITH_STANDARD',
    FILTER_ACADEMIC_PLAN = 'FILTER_ACADEMIC_PLAN',
    CURRENT_PAGE = 'CURRENT_PAGE',
    ALL_COUNT = 'ALL_COUNT',
    SORTING = 'SORTING',
    SORTING_FIELD = 'SORTING_FIELD',
    SORTING_MODE = 'SORTING_MODE',
    COMPETENCE = 'COMPETENCE',
    INDICATORS = 'INDICATORS',
}

export enum fetchingTypes {
    GET_COMPETENCES = 'GET_COMPETENCES',
    DELETE_COMPETENCE = 'DELETE_COMPETENCE',
    UPDATE_COMPETENCE = 'UPDATE_COMPETENCE',
    CREATE_COMPETENCE = 'CREATE_COMPETENCE',
    GET_COMPETENCE = 'GET_COMPETENCE',
    GET_INDICATORS = 'GET_INDICATORS',
    CREATE_INDICATOR = 'CREATE_INDICATOR',
    UPDATE_INDICATOR = 'UPDATE_INDICATOR',
    DELETE_INDICATOR = 'DELETE_INDICATOR',
    GET_INDICATORS_DEPENDS_COMPETENCE = 'GET_INDICATORS_DEPENDS_COMPETENCE',
}

export enum CompetenceFields {
    ID = 'id',
    TITLE = 'name',
    NUMBER = 'number',
}
