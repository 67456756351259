import {Dispatch} from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

import actions from "./actions";
import {getEntityToEntityList, getCurrentPage, getSearchQuery, getAllCount, getSortingMode, getSortingField} from './getters';
import {EntityToEntityActions} from "./types";

import {rootState} from "../../store/reducers";

const mapStateToProps = (state: rootState) => {
  return {
    trainingEntities: getEntityToEntityList(state),
    currentPage: getCurrentPage(state),
    searchQuery: getSearchQuery(state),
    allCount: getAllCount(state),
    sortingField: getSortingField(state),
    sortingMode: getSortingMode(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<EntityToEntityActions>) => ({
  // @ts-ignore
  actions: bindActionCreators(actions, dispatch),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps);
