export enum fetchingTypes {
    GET_MODULES = 'GET_MODULES',
    GET_PLANS = 'GET_PLANS',
    ADD_MODULES_TO_PLAN = 'ADD_MODULES_TO_PLAN'
}


export enum Qualifications {
    BACHELOR = 'bachelor',
    MASTER = 'master',
    ALL_LEVELS = '',
}
