export const types = [
  'Аннотация',
  'Видеоролик',
  'Дебаты',
  'Деловая (ролевая) игра',
  'Деловая игра',
  'Дискуссия',
  'Доклад',
  'Домашнее задание',
  'Проектное задание',
  'Задача',
  'Исследовательская работа',
  'Кейс',
  'Коллоквиум',
  'Контрольная работа',
  'Круглый стол',
  'Лабораторная работа',
  'Опрос',
  'Отчет',
  'Портфолио',
  'Практическая работа',
  'Презентация',
  'Проект',
  'Расчетно-графические работы',
  'Резюме',
  'Реферат',
  'Рецензия',
  'Ситуационное (проблемное) задание',
  'Сообщение',
  'Тезисы',
  'Тест',
  'Расчет',
  'Эссе',
  'Электронное тестирование в ЦДО',
]